<template>
  <div class="m-1">
    <b-row>
      <!-- First Stamenet -->
      <b-col cols="5">
        <b-row>
          <!-- First SubStatement -->
          <b-col cols="6">
            <b-form-group v-if="client.program_id == 4" label="CO-APP">
              <b-skeleton v-if="validateCoAppOn" type="input" />
              <div
                v-else
                class="form-group-input2 d-flex justify-content-between align-items-center"
                style="min-height: 3rem !important"
                :class="statusDark"
              >
                <div>
                  <span
                    v-if="client.coapp_name != false"
                    id="date"
                    name="start"
                    class="cursor-pointer text-primary-light"
                    @click="coApplicant(true)"
                    >{{ client.coapp_name }}</span
                  >
                  <span v-else id="date" name="start">None</span>
                </div>

                <div>
                  <span
                    v-if="client.coapp_name == false"
                    name="start"
                    class="cursor-pointer text-primary-light"
                    @click="coApplicant(false)"
                    >ADD</span
                  >

                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="cursor-pointer ml-1"
                    size="15"
                    @click="openModalTrackingCoApplicant"
                  />
                </div>
              </div>
            </b-form-group>
            <!-- Status -->
            <b-form-group label="Status">
              <div
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex align-items-center"
                :class="statusDark"
              >
                <span>{{ arrayStatusLead[client.status_l - 1] }}</span>
              </div>
            </b-form-group>
            <!-- Start Date -->
            <b-form-group label="Start Date">
              <div
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex align-items-center"
                :class="statusDark"
              >
                <span>{{ client.start_date | myGlobal }}</span>
              </div>
            </b-form-group>
            <!-- Mobile -->
            <b-form-group label="Mobile">
              <div
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex justify-content-between align-items-center"
                :class="statusDark"
              >
                <span>{{ client.mobile }}</span>
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="cursor-pointer"
                  size="15"
                  @click="openModalTrackingMobile(3, 'MOBILE')"
                />
              </div>
            </b-form-group>
            <!-- Email -->
            <b-form-group label="Email">
              <div
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex align-items-center"
                :class="statusDark"
              >
                <span>{{ client.email }}</span>
              </div>
            </b-form-group>
            <!-- Address -->
            <b-form-group label="Address">
              <div
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex justify-content-between align-items-center"
                :class="statusDark"
              >
                <span id="date" name="start">
                  {{ client.street }}
                  {{ client.city }}
                  <span>,</span>
                  {{ client.states_eeuu_slug }}
                  <span>,</span>
                  {{ client.zipcode }}
                </span>
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="cursor-pointer"
                  size="15"
                  @click="openModalTrackingMobile(1, 'ADDRESS')"
                />
              </div>
            </b-form-group>
          </b-col>
          <!-- Second SubStatement -->
          <b-col cols="6">
            <!-- Advisor -->
            <b-form-group label="Advisor">
              <b-skeleton v-if="validateAdvisorOn" type="input" />
              <div
                v-else
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex justify-content-between align-items-center"
                :class="statusDark"
              >
                <div class="d-flex align-items-center">
                  <div
                    :title="
                      client.state_advisor == 1
                        ? 'Active'
                        : client.state_advisor == 2
                        ? 'Busy'
                        : client.state_advisor == 3
                        ? 'Away'
                        : 'Offline'
                    "
                    :style="
                      client.state_advisor == 1
                        ? 'background:green'
                        : client.state_advisor == 2
                        ? 'background:orange'
                        : client.state_advisor == 3
                        ? 'background:red'
                        : 'background:#ccc'
                    "
                    style="height: 10px; width: 10px; border-radius: 50%"
                  />
                  <span class="ml-1">{{ client.advisor_name }}</span>
                </div>
                <div class="d-flex">
                  <feather-icon
                    icon="Edit2Icon"
                    class="cursor-pointer ml-1"
                    size="15"
                    @click="openModalChangeAdvisor"
                  />
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="cursor-pointer ml-1"
                    size="15"
                    @click="openModalHistoryAdvisor"
                  />
                </div>
              </div>
            </b-form-group>
            <!-- Admin -->
            <b-form-group
              v-if="
                client.module == 5 || client.module == 6 || client.module == 7
              "
              label="Admin"
            >
              <div
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex align-items-center"
                :class="statusDark"
              >
                <div
                  :title="
                    client.state_admin == 1
                      ? 'Active'
                      : client.state_admin == 2
                      ? 'Busy'
                      : client.state_admin == 3
                      ? 'Away'
                      : 'Offline'
                  "
                  :style="
                    client.state_admin == 1
                      ? 'background:green'
                      : client.state_admin == 2
                      ? 'background:orange'
                      : client.state_admin == 3
                      ? 'background:red'
                      : 'background:#ccc'
                  "
                  style="height: 10px; width: 10px; border-radius: 50%"
                />
                <span class="ml-1">{{ client.admin_name }}</span>
              </div>
            </b-form-group>
            <!-- Specialist -->
            <b-form-group
              v-if="
                client.module == 5 || client.module == 6 || client.module == 7
              "
              label="Specialist"
            >
              <div
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex align-items-center"
                :class="statusDark"
              >
                <div
                  :title="
                    client.state_spec == 1
                      ? 'Active'
                      : client.state_spec == 2
                      ? 'Busy'
                      : client.state_spec == 3
                      ? 'Away'
                      : 'Offline'
                  "
                  :style="
                    client.state_spec == 1
                      ? 'background:green'
                      : client.state_spec == 2
                      ? 'background:orange'
                      : client.state_spec == 3
                      ? 'background:red'
                      : 'background:#ccc'
                  "
                  style="height: 10px; width: 10px; border-radius: 50%"
                />
                <span class="ml-1">{{ client.spec_name }}</span>
              </div>
            </b-form-group>
            <!-- Catcher -->
            <b-form-group
              v-if="
                client.module == 2 ||
                client.module == 3 ||
                client.module == 8 ||
                client.module == 10 ||
                client.module == 11
              "
              label="Catcher"
            >
              <div
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex align-items-center"
                :class="statusDark"
              >
                <span>{{ client.captured_name }}</span>
              </div>
            </b-form-group>
            <!-- Seller -->
            <b-form-group
              v-if="
                client.module == 2 ||
                client.module == 3 ||
                client.module == 8 ||
                client.module == 10 ||
                client.module == 11
              "
              label="Seller"
            >
              <div
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex align-items-center"
                :class="statusDark"
              >
                <span>{{ client.seller_name }}</span>
              </div>
            </b-form-group>
            <!-- Monthly Pay -->

            <b-form-group label="Pay">
              <b-skeleton v-if="validateMontlyPayOn" type="input" />
              <div
                v-if="!validateMontlyPayOn"
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex justify-content-between align-items-center w-100"
                :class="statusDark"
              >
                <div class="d-flex">
                  <span v-if="client.monthly_amount" class="mr-1"
                    >$ {{ client.monthly_amount }}</span
                  >
                  <status-monthly-pay />
                </div>
                <div class="d-flex">
                  <feather-icon
                    icon="Edit2Icon"
                    class="cursor-pointer ml-1"
                    size="15"
                    @click="openModalMonthlyPayment"
                  />
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="cursor-pointer ml-1"
                    size="15"
                    @click="openModalHistoryPayment"
                  />
                </div>
              </div>
            </b-form-group>
            <!-- Charge -->
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col lg="12">
            <b-form-group label="Charge">
              <b-skeleton v-if="validateChargeOn" type="input" />
              <div
                v-else
                style="min-height: 3rem !important"
                class="form-group-input2 d-flex justify-content-between"
                :class="statusDark"
              >
                <div>
                  <tabler-icon
                    v-if="client.charges == 0"
                    icon="CircleCheckIcon"
                    class="cursor-pointer text-success"
                    size="22"
                    title="Approve Charge"
                    @click="openApproveCharge(client.charges)"
                  />
                  <b-img
                    v-else
                    class="cursor-pointer"
                    title="Approve Charge"
                    :src="assetsImg + '/images/icons/dcharge.ico'"
                    @click="openApproveCharge(client.charges)"
                  />
                </div>
                <div>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="cursor-pointer"
                    size="15"
                    @click="openModalTrackingMobile(7, 'Charge')"
                  />
                </div>
              </div>
            </b-form-group>
          </b-col> -->
          <b-col lg="10" v-if="client.module == 7">
            <b-form-group label="Goal">
              <v-select
                v-model="typegoal"
                :options="typegoals"
                :dir="'ltr'"
                :multiple="true"
                transition="multiple"
                label="name"
                :class="[{ 'disable-class': disableGoals }]"
              />
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <div
              class="d-flex align-items-center justify-content-start align-content-center h-100"
              style="margin-top: 5px"
            >
              <tabler-icon
                v-if="(disableGoals == true ? true : false) && isAdministration"
                class="cursor-pointer text-warning mr-1"
                icon="PencilIcon"
                size="20"
                @click="changeGoals"
              />
              <feather-icon
                v-if="disableGoals == true ? false : true"
                class="cursor-pointer text-danger mr-1"
                icon="XCircleIcon"
                size="20"
                @click="cancelGoals"
              />
              <feather-icon
                v-if="disableGoals == true ? false : true"
                class="cursor-pointer text-success"
                icon="CheckCircleIcon"
                size="20"
                @click="updateGoals"
              />
            </div>
          </b-col>
          <b-col lg="12">
            <b-form-group label="Claim">
              <div class="d-flex justify-content-between">
                <b-skeleton v-if="validateNewClaim" type="input" />
                <div
                  v-if="!validateNewClaim"
                  class="form-group-input2 d-flex justify-content-between w-100"
                  :class="statusDark"
                >
                  <div class="d-flex">
                    <span class="mr-1">{{
                      lastClaim ? lastClaim.type : ""
                    }}</span>
                    <span
                      ><span class="mr-2 mb-0">{{
                        lastClaim ? lastClaim.code : ""
                      }}</span>
                      <div
                        v-if="lastClaim"
                        style="
                          height: 10px;
                          background: rgb(0, 204, 0);
                          width: 10px;
                          border-radius: 50%;
                          transform: translate(0px, 60%);
                          float: right;
                        "
                      />
                    </span>
                  </div>
                  <div class="d-flex">
                    <feather-icon
                      icon="MoreVerticalIcon"
                      class="cursor-pointer ml-1"
                      size="15"
                      @click="openModalTrackingHistoryClaims"
                    />
                  </div>
                </div>
                <b-button
                  v-if="isAdministration"
                  size="sm"
                  class="ml-1"
                  variant="info"
                  @click="openCreateNewClaim"
                >
                  New Claim
                </b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="10">
            <b-form-group label="Memo">
              <div
                @click="editmemo"
                v-if="!campeditmemo"
                class="memo-class"
                v-html="client.memo"
              ></div>
              <b-textarea
                v-else
                v-model="client.memo"
                class="d-flex justify-content-between w-100"
              />
              <div
                v-if="campuserchangememo"
                class="col-lg-12"
                style="padding: 0 10px; text-align: right"
              >
                <span style="color: #cccccc">
                  Updated by:
                  {{ client.user_memo == null ? "-" : client.user_memo }} /
                  <span v-if="client.date_memo == null">-</span>
                  <span v-else>{{ client.date_memo | myGlobalDay }}</span>
                </span>
              </div>
            </b-form-group>
          </b-col>

          <b-col v-if="campeditmemo" class="mt-4">
            <feather-icon
              icon="CheckIcon"
              class="cursor-pointer ml-1 text-success"
              size="20"
              @click="saveeditmemo(client.id)" />
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer ml-1 text-danger"
              size="20"
              @click="canceleditmemo"
          /></b-col>
        </b-row>
      </b-col>
      <!-- Second Statement -->
      <b-col cols="7">
        <task-notes />
        <b-row class="mt-1">
          <b-col>
            <b-form-group
              label="SCORES"
              :label-style="isDarkSkin ? 'text-white !important' : 'text-black'"
              :label-class="
                isDarkSkin
                  ? 'k-font-weight-bold font-medium-1'
                  : 'k-font-weight-bold font-medium-1'
              "
            >
              <b-table
                ref="refScoresList"
                :items="allscores"
                :fields="fields"
                primary-key="id_ncr"
                table-class="text-nowrap"
                responsive="sm"
                show-empty
                style="margin-bottom: 0px"
                sticky-header="36vh "
                :busy.sync="isBusy"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>

                <template #head(date)>
                  <span>Date</span>
                </template>

                <template #head(file)>
                  <span>File</span>
                </template>
                <template #head(equifax)>
                  <span
                    style="
                      color: #981e32;
                      font-weight: bold;
                      text-align: center;
                      font-style: italic;
                    "
                    >EQUIFAX</span
                  >
                </template>

                <template #head(experian)>
                  <span
                    style="
                      color: #0566b7;
                      font-weight: bold;
                      text-align: center;
                    "
                    >Experian</span
                  >
                </template>
                <template #head(trans_union)>
                  <template style="font-weight: bold; text-align: center">
                    <span>Trans</span>
                    <span style="color: #4d917a"> Union</span>
                  </template>
                </template>
                <template #cell(date)="data">
                  <div style="width: 60px">
                    {{ data.item.date | myGlobal }}
                  </div>
                </template>
                <template #cell(file)="data">
                  <div style="width: 10px">
                    <a
                      v-if="data.item.route_pdf != null"
                      :href="data.item.route_pdf"
                      target="_blanck"
                    >
                      <amg-icon
                        size="18"
                        class="text-danger"
                        icon="FilePdfIcon"
                      />
                    </a>

                    <span v-else>-- </span>
                  </div>
                </template>

                <template #cell(equifax)="data">
                  <div>
                    <span
                      style="
                        width: 20px;
                        color: #981e32;
                        font-weight: bold;
                        font-size: 15px;
                        border-top: none;
                        text-align: center;
                      "
                      >{{ data.item.equifax }}
                    </span>
                  </div>
                </template>

                <template #cell(experian)="data">
                  <template>
                    <span
                      style="
                        color: #0566b7;
                        font-weight: bold;
                        font-size: 15px;
                        border-top: none;
                        text-align: center;
                      "
                      >{{ data.item.experian }}
                    </span>
                  </template>
                </template>

                <template #cell(trans_union)="data">
                  <template>
                    <span
                      style="
                        color: #4d917a;
                        font-weight: bold;
                        font-size: 15px;
                        border-top: none;
                        text-align: center;
                      "
                      >{{ data.item.transunion }}
                    </span>
                  </template>
                </template>
              </b-table>
              <div
                v-if="this.currentUser.role_id == 1 || this.moduleId == 4"
                style="text-align: right; margin-top: 10px"
              >
                <b-button
                  variant="primary"
                  style="
                    cursor: pointer;
                    background: #ccc;
                    padding: 5px 8px;
                    text-align: center;
                    font-weight: bold;
                    border-radius: 5px;
                    margin-right: 5px;
                  "
                  @click="openmodalscore(null)"
                  >Add</b-button
                >
                <span>|</span>
                <b-button
                  variant="info"
                  style="
                    cursor: pointer;
                    background: #ccc;
                    padding: 5px 8px;
                    text-align: center;
                    font-weight: bold;
                    border-radius: 5px;
                    margin-left: 5px;
                  "
                  @click="openmodalscoresall"
                  >Edit</b-button
                >
              </div>
            </b-form-group></b-col
          >
        </b-row>
      </b-col>
    </b-row>

    <modal-history
      v-if="modalHistory"
      :id="idClient"
      :modal-history="modalHistory"
      :name-program="client.program_name"
      :name-client="client.client_name"
      @closeModalHistory="closeModalHistory"
    />
    <modal-co-applicant
      v-if="modalApplicant"
      :valo-co="valoCo"
      @refresh="refresh"
      @close="closeModalApplicant"
    />
    <modal-tracking-leads
      v-if="modalTrackingMobile"
      :modal-tracking-mobile="modalTrackingMobile"
      :type="typefields"
      :namefiels="namefiels"
      @closeModalTracking="closeModalTracking"
    />
    <modal-change-advisor
      v-if="modalChangeAdvisor"
      @refresh="refresh"
      @close="closeModalChangeAdvisor"
    />
    <modal-history-advisor
      v-if="modalHistoryAdvisor"
      @closeModalHistoryAdvisor="closeModalHistoryAdvisor"
    />
    <modal-monthly-edit
      v-if="modalMonthlyPayment"
      :modal-monthly-payment="modalMonthlyPayment"
      @updateClient="refresh"
      @close="closeModalMonthlyPayment"
    />
    <modal-history-monthly-payment
      v-if="modalHistoryPayment"
      @close="closeModalHistoryPayment"
    />

    <modal-tracking-co-applicant
      v-if="modalTrackingCoApplicantOn"
      @close="closeModalTrackingCoApplicant"
    />

    <contract-fee-modal
      v-if="modalContractOn"
      :modal="modal"
      :contract-fee="contractFee"
      @close="closeContractFeeModal"
      @reload="refresh"
    />

    <modal-tracking-history-claims
      v-if="modalHistoryClaims"
      @close="closeModalTrackingHistoryClaims"
    />

    <modal-new-claim
      v-if="modalCreateNewClaim"
      ref="newClaim"
      :is-from-account="true"
      :account-id="$route.params.idClient"
      :is-new-claim="true"
      @refresh="refresh"
      @onClose="closeCreateNewClaim"
    />
    <modal-add-score
      v-if="modalAddScore"
      @refresh="scores"
      @close="closeModalAddScore"
    />
    <modal-scores
      v-if="modalScores"
      @allScores="scores"
      @close="closeModalScore"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";
// Import Services
import ModalTrackingCoApplicant from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/modal-history-co-applicant/ModalTrackingCoApplicant.vue";
import ContractFeeModal from "@/views/crm/views/sales-made/components/modals/ContractFeeModal.vue";
import ModalTrackingHistoryClaims from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalTrackingHistoryClaims";
import ModalNewClaim from "@/views/debt-solution/views/claims/components/ModalNewClaim.vue";
import ModalAddScore from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalAddScore";
import ModalScores from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalScores";
import TaskNotes from "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services.js";
// Import Component
import ModalCoApplicant from "@/views/commons/components/clients/dashboard/information-client/modals/ModalCoApplicant.vue";
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ModalTrackingLeads from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalTrackingLeads.vue";
import ModalChangeAdvisor from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalChangeAdvisor.vue";
import ModalHistoryAdvisor from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryAdvisor.vue";
import StatusMonthlyPay from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/StatusMonthlyPay.vue";
import ModalMonthlyEdit from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalMonthlyEdit.vue";
import ModalHistoryMonthlyPayment from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryMonthlyPayment.vue";
import ClientsBoostDashboardServices from "@/views/boost-credit/views/clients/dashboard/services/clients.boost.dashboard.services";

export default {
  components: {
    vSelect,
    TaskNotes,
    StatusAccount,
    // Modals
    ModalHistory,
    ModalCoApplicant,
    ModalTrackingLeads,
    ModalChangeAdvisor,
    ModalHistoryAdvisor,
    StatusMonthlyPay,
    ModalMonthlyEdit,
    ModalHistoryMonthlyPayment,
    ModalTrackingCoApplicant,
    ContractFeeModal,
    ModalTrackingHistoryClaims,
    ModalNewClaim,
    ModalAddScore,
    ModalScores,
  },
  data() {
    return {
      disableGoals: true,
      typegoal: null,
      typegoals: [],
      fields: [
        { key: "date", label: "Date" },

        { key: "equifax", label: "Equifax" },
        { key: "experian", label: "Experian" },
        { key: "trans_union", label: "Trans Union" },
      ],
      isBusy: false,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      lastClaim: null,
      idClient: "",
      created: "",
      balanceprops: "",
      advisor: null,
      status: null,
      date_start: "",
      status_welco: null,
      lead_status: null,
      cstreet: null,
      ccity: "",
      cstates_eeuu_slug: "",
      czipcode: null,
      selementCost: null,
      advisors: [],
      allscores: [],
      viewp: true,
      valoCo: null,
      typefields: "",
      namefiels: "",
      typeApprove: "",
      //   modals
      modalHistoryClaims: false,
      modalHistory: false,
      modalApplicant: false,
      modalTrackingMobile: false,
      modalChangeAdvisor: false,
      modalHistoryAdvisor: false,
      modalMonthlyPayment: false,
      modalHistoryPayment: false,
      modalTrackingCoApplicantOn: false,
      modalContractOn: false,
      modalCreateNewClaim: false,
      modalAddScore: false,
      modalScores: false,
      validateCoAppOn: false,
      validateAdvisorOn: false,
      validateMontlyPayOn: false,
      validateChargeOn: false,
      validateNewClaim: false,
      arrayStatusLead: [
        "U.S Citizen",
        "Residente Alien",
        "Non-Resident Alien",
        "Student Green Card",
        "Work Green Card",
      ],
      modal: {},
      contractFee: {
        programName: "",
        clientName: "",
        saleId: "",
        id: "",
        initialPaymentStatus: null,
        editModal: false,
        statusSale: null,
      },
      campeditmemo: false,
      campuserchangememo: true,
      temporalMemo: "",
    };
  },
  computed: {
    isAdministration() {
      return this.$route.matched[0].meta.module !== 4;
    },
    ...mapGetters({
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      clientResponse: "DebtSolutionClients/G_CLIENTS_RESPONSE",
    }),
    statusDark() {
      return this.skin == "dark" ? "dark" : "";
    },
    statusRedirect() {
      let router;
      switch (this.moduleId) {
        case 2:
          router = "/crm/clients/edit/";
          break;
        case 6:
          router = "/creditexperts/clients/edit/";
          break;
        case 3:
          router = "/bussiness/clients/edit/";
          break;
        case 5:
          router = "/debtsolution/clients/edit/";
          break;
        case 7:
          router = "/boostcredit/clients/edit/";

          break;
        case 8:
          router = "/taxresearch/clients/edit/";
          break;
        case 10:
          router = "/claimdepartment/clients/edit/";
          break;
        default:
          router = "";
      }
      return router;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  watch: {
    async client(newVal) {
      if (newVal) {
        await this.scores();
        this.typegoal = this.client.goals;
      }
    },
  },
  async mounted() {
    await this.listTypeGoal();
    await this.getLastClaim();
    await this.scores();
  },

  methods: {
    cancelGoals() {
      this.disableGoals = true;
    },
    changeGoals() {
      this.disableGoals = false;
    },
    openmodalscore() {
      this.modalAddScore = true;
    },
    closeModalAddScore() {
      this.modalAddScore = false;
    },

    async openmodalscoresall() {
      await this.scores();
      this.modalScores = true;
    },
    async closeModalScore() {
      await this.scores();
      this.modalScores = false;
    },
    async saveeditmemo() {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            id: this.$route.params.idClient,
            user_id: this.currentUser.user_id,
            memo: this.client.memo,
          };
          const data = await ClientDashboardService.editMemo(params);
          if (data.status === 200) {
            this.campeditmemo = false;
            this.campuserchangememo = true;
            await this.refresh();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    editmemo() {
      this.temporalMemo = this.client.memo;
      this.campeditmemo = true;
      this.campuserchangememo = false;
    },
    canceleditmemo() {
      this.client.memo = this.temporalMemo;

      this.campeditmemo = false;
      this.campuserchangememo = true;
    },

    openCreateNewClaim() {
      this.modalCreateNewClaim = true;
    },
    closeCreateNewClaim() {
      this.modalCreateNewClaim = false;
    },
    openModalTrackingHistoryClaims() {
      this.modalHistoryClaims = true;
    },
    closeModalTrackingHistoryClaims() {
      this.modalHistoryClaims = false;
    },

    openContractFeeModal() {
      this.modal.contract_fee = true;
      (this.contractFee.programName = this.client.program_name),
        (this.contractFee.clientName = this.client.client_name),
        (this.contractFee.saleId = this.client.sale_id),
        (this.contractFee.id = this.client.lead_id),
        (this.contractFee.initialAmount = 2),
        (this.contractFee.editModal = false),
        (this.contractFee.statusSale = 4),
        (this.modalContractOn = true);
    },
    closeContractFeeModal() {
      this.modalContractOn = false;
    },
    async refresh() {
      if (this.modalApplicant === true) {
        this.validateCoAppOn = true;
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateCoAppOn = false;
        }
      }
      if (this.modalChangeAdvisor == true) {
        this.validateAdvisorOn = true;
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateAdvisorOn = false;
        }
      }
      if (this.modalMonthlyPayment === true) {
        this.validateMontlyPayOn = true;
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateMontlyPayOn = false;
        }
      }
      if (this.modalCreateNewClaim === true) {
        this.validateNewClaim = true;
        const data = await this.getLastClaim();
        if (data) {
          this.validateNewClaim = false;
        }
      }

      if (this.validateChargeOn == true) {
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateChargeOn = false;
        }
      }
    },

    openModalTrackingCoApplicant() {
      this.modalTrackingCoApplicantOn = true;
    },

    closeModalTrackingCoApplicant() {
      this.modalTrackingCoApplicantOn = false;
    },

    //   OpenModals
    openModalHistory() {
      this.modalHistory = true;
    },
    closeModalHistory() {
      this.modalHistory = false;
    },
    coApplicant(valoCo) {
      this.valoCo = valoCo;
      this.modalApplicant = true;
    },
    closeModalApplicant() {
      this.modalApplicant = false;
    },
    openModalTrackingMobile(type, name) {
      this.typefields = type;
      this.namefiels = name;
      this.modalTrackingMobile = true;
    },
    closeModalTracking() {
      this.modalTrackingMobile = false;
    },
    openModalChangeAdvisor() {
      this.modalChangeAdvisor = true;
    },
    closeModalChangeAdvisor() {
      this.modalChangeAdvisor = false;
    },
    openModalHistoryAdvisor() {
      this.modalHistoryAdvisor = true;
    },
    closeModalHistoryAdvisor() {
      this.modalHistoryAdvisor = false;
    },
    openModalMonthlyPayment() {
      this.modalMonthlyPayment = true;
    },
    closeModalMonthlyPayment() {
      this.modalMonthlyPayment = false;
    },
    openModalHistoryPayment() {
      this.modalHistoryPayment = true;
    },
    closeModalHistoryPayment() {
      this.modalHistoryPayment = false;
    },
    // openApproveCharge(id_type) {
    //   if (id_type == 0) {
    //     if (this.currentUser.role_id == 1 || this.currentUser.role_id == 2) {
    //       this.chargesdisabled();
    //     } else {
    //       this.typeApprove = 1;
    //       this.ModalApproveSupervisor = true;
    //     }
    //   } else {
    //     this.chargesdisabled();
    //   }
    // },
    // async chargesdisabled() {
    //   const confirm = await this.showConfirmSwal();
    //   if (confirm.isConfirmed) {
    //     this.validateChargeOn = true;
    //     try {
    //       const params = {
    //         id: this.$route.params.idClient,
    //         iduser: this.currentUser.user_id,
    //       };
    //       const data = await ClientDashboardService.updateDrb(params);
    //       if (data.status === 200) {
    //         await this.refresh();
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // },
    async searchAccount() {
      try {
        this.addPreloader();
        const response = await ClientDashboardService.search({
          id: this.idClient,
        });
        this.created = this.client.creates;
        this.balanceprops = this.client.balance;
        this.advisor = this.client.advisor_id;
        this.status = this.client.status;
        this.date_start = this.client.start_date;
        this.status_welco = this.client.s_welcome;
        this.lead_status = this.client.status_l;
        this.cstreet = this.client.street;
        this.ccity = this.client.city;
        this.cstates_eeuu_slug = this.client.state_name;
        this.czipcode = this.client.zipcode;
        this.selementCost = this.client.selement_cost;
        this.typegoal =
          this.client.goals == null ? [] : JSON.parse(this.client.goals);
        if ((this.client.coappname = "")) {
          this.client.coappname = false;
        }
        let module = 0;
        switch (parseInt(this.status)) {
          case 1:
            this.status_name = "Active";
            break;
          case 2:
            this.status_name = "Hold";
            break;
          case 3:
            this.status_name = "Transition";
            break;
          case 4:
            this.status_name = "Canceled";
            break;
          case 5:
            this.status_name = "Loyal";
            break;
          case 6:
            this.status_name = "Closed";
            break;
        }

        switch (this.client.program_id) {
          case 1:
            module = 3;
            break;
          case 2:
            module = 7;
            break;
          case 3:
            module = 6;
            break;
          case 4:
            module = 5;
            break;
          case 5:
            module = 8;
            break;
          case 6:
            module = 10;
            break;
        }

        const data = await ClientDashboardService.userModule(module, {
          roles: "[2,3]",
          type: "1",
        });
        this.advisors = data.data;
        this.removePreloader();
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with Account!"
        );
        this.removePreloader();
      }
    },
    async scores() {
      try {
        const data = await ClientDashboardService.allScore({
          id: this.client.lead_id,
        });
        if (data.status === 200) {
          this.allscores = data.data;
        }

        return this.allscores;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with score!"
        );
      }
    },
    async updateGoals() {
      try {
        this.spinnerOn = true;
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const params = {
            idaccount: this.$route.params.idClient,
            goals: this.typegoal,
          };

          const data = await ClientsBoostDashboardServices.updateGoals(params);

          if (data.status === 200) {
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async listTypeGoal() {
      try {
        const data = await ClientsBoostDashboardServices.getAllTypeGoal();
        if (data.status === 200) {
          this.typegoals = data.data;
        }

        return this.typegoals;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with score!"
        );
      }
    },

    async getLastClaim() {
      try {
        const params = {
          account_id: this.$route.params.idClient,
        };
        const data = await ClientDashboardService.getLastClaim(params);
        this.lastClaim = data.data[0];
        return this.lastClaim;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style scoped>
.multiselect__tags {
  min-height: 30px;
}

.multiselect__tag {
  font-size: 12px;
}

.multiselect {
  min-height: 30px;
}

.multiselect__select {
  height: 30px;
}

.form-group-input {
  padding: 0.438rem 1rem;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}

.disable-class {
  pointer-events: none;

  /* for "disabled" effect */
  opacity: 0.5;
}
.memo-class {
  height: 58px;
  width: 100%;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.438rem 1rem;
  overflow: auto;
  white-space: pre-wrap;
}
</style>
